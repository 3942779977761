/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 *
 * You may delete this file and its occurrences from the project filesystem if you are using react-scripts
 */
import React from 'react';
import Interpretation from 'views/Interpretation';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';
import SEO from '../src/components/SEO';

export const Head = () => (
  <SEO description="Interpretation tools for structural geology and sedimentology, with photogrammetric or lidar data" />
);
const InterpretationPage = () => {
  return <WithLayout component={Interpretation} layout={Main} />;
};

export default InterpretationPage;
