import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useMediaQuery, Grid, Typography, colors } from '@mui/material';
import IconAlternate from 'components/molecules/IconAlternate/IconAlternate';
import SectionHeader from 'components/molecules/SectionHeader/SectionHeader';
import DescriptionListIcon from 'components/organisms/DescriptionListIcon/DescriptionListIcon';
import Section from 'components/organisms/Section/Section';

const useStyles = makeStyles(() => ({
  fontWeight900: {
    fontWeight: 900,
  },
  noPaddingBottom: {
    paddingBottom: 0,
  },
  noPaddingTop: {
    paddingTop: 0,
  },
}));

const data = [
  {
    icon: '/images/svg/brain.svg',
    title: 'Built for Scientists and Engineers in industry and academia',
    subtitle:
      'Designed and developed to give geoscientists and engineers the tools they need to work effectively.',
  },
  {
    icon: '/images/svg/search-location.svg',
    title: 'Based on research, developed for industry',
    subtitle:
      'Built upon extensive research into the use of digital outcrop data for subsurface characterisation.',
  },
  {
    icon: '/images/svg/info-circle.svg',
    title: 'Documentation and Support',
    subtitle:
      'Online documentation and video tutorials help get you going. Dont know how to do something in VRGS? Just ask!',
  },
];
const Services = ({ className, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const title = (
    <Typography variant="h2" component="span" className={classes.fontWeight900}>
      Interpret digital outcrop models from point-clouds or meshes&nbsp;
      <Typography component="span" variant="inherit" color="primary">
        efficiently and accurately
      </Typography>
    </Typography>
  );

  const subtitle = 'Developed with 16 years of academic research.';

  return (
    <div className={className} {...rest}>
      <Section narrow className={classes.noPaddingBottom}>
        <SectionHeader
          title={title}
          subtitle={subtitle}
          align="center"
          titleProps={{
            variant: 'h2',
            color: 'textPrimary',
          }}
          data-aos="fade-up"
        />
      </Section>
      <Section className={classes.noPaddingTop}>
        <Grid container spacing={isMd ? 4 : 2}>
          {data.map((item, index) => (
            <Grid key={index} item xs={12} sm={4} data-aos={'fade-up'}>
              <DescriptionListIcon
                title={item.title}
                subtitle={item.subtitle}
                icon={
                  <IconAlternate
                    fontIconClass={item.icon}
                    size="medium"
                    color={colors.indigo}
                    alt={item.subtitle}
                    iconProps={{ width: '80%', height: '80%' }}
                  />
                }
                align="left"
              />
            </Grid>
          ))}
        </Grid>
      </Section>
    </div>
  );
};

Services.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Services;
