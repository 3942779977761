import React from 'react';
import PropTypes from 'prop-types';
import { Grid, colors } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import IconAlternate from 'components/molecules/IconAlternate/IconAlternate';
import SectionHeader from 'components/molecules/SectionHeader/SectionHeader';
import DescriptionListIcon from 'components/organisms/DescriptionListIcon/DescriptionListIcon';
import CardBase from 'components/organisms/CardBase/CardBase';

const useStyles = makeStyles(() => ({
  fontWeight900: {
    fontWeight: 900,
  },
}));

const data = [
  {
    icon: '/images/svg/pencil.svg',
    color: colors.indigo,
    title: 'Polylines',
    subtitle:
      'The basic interpretation tools for mapping beds, horizons or other trace type features.',
  },
  {
    icon: '/images/svg/compass.svg',
    color: colors.indigo,
    title: 'Orientations',
    subtitle:
      'Need to measure the dip and dip-direction of a plane? This is the tool for you.',
  },
  {
    icon: '/images/svg/sed-log.svg',
    color: colors.indigo,
    title: 'Sedimentary Logs',
    subtitle:
      'Create a sedimentary log in your data, and add detailed additional information in the 2D log editor.',
  },
  {
    icon: '/images/svg/draw-polygon.svg',
    color: colors.indigo,
    title: 'Geobodies',
    subtitle: 'Map geobodies and extract statistical information on them.',
  },
  {
    icon: '/images/svg/grip-lines.svg',
    color: colors.indigo,
    title: 'Zone Mapping',
    subtitle: 'Delimit zones and intervals within your models.',
  },
  {
    icon: '/images/svg/map-marker-alt.svg',
    color: colors.indigo,
    title: 'Waypoints',
    subtitle: 'Add waypoints and position markers in your data.',
  },
  {
    icon: '/images/svg/image.svg',
    color: colors.indigo,
    title: 'Billboards',
    subtitle:
      'Add images into your 3D models, and add multiple images to act as in-model presentations. Ideal for virtual field trips.',
  },
  {
    icon: '/images/svg/camera.svg',
    color: colors.indigo,
    title: 'Multimedia',
    subtitle:
      'Link a wide range of data into your models or link to external websites.',
  },
  {
    icon: '/images/svg/laptop-code.svg',
    color: colors.indigo,
    title: 'Continually Developing',
    subtitle:
      "Can't find the tool you need? Get in contact with us! Regular improvements and updates to VRGS are driven by user feedback.",
  },
];

const Features = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={className} {...rest}>
      <SectionHeader
        title="An extensive set of interpretation tools to suit a wide range of cases."
        fadeUp
        titleProps={{
          variant: 'h3',
          color: 'textPrimary',
          className: classes.fontWeight900,
        }}
      />
      <Grid container spacing={2}>
        {data.map((adv, index) => (
          <Grid
            key={index}
            item
            container
            alignItems="center"
            direction="column"
            xs={6}
            md={4}
            data-aos="fade-up"
          >
            <CardBase
              liftUp
              variant="outlined"
              style={{ borderTop: `5px solid ${adv.color[500]}` }}
            >
              <DescriptionListIcon
                icon={
                  <IconAlternate
                    fontIconClass={adv.icon}
                    alt={adv.subtitle}
                    color={adv.color}
                    shape="square"
                    size="small"
                    iconProps={{ width: '80%', height: '80%' }}
                  />
                }
                title={adv.title}
                subtitle={adv.subtitle}
                align="left"
              />
            </CardBase>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

Features.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Features;
