import React from 'react';
import { Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Section from 'components/organisms/Section/Section';
import SectionAlternate from 'components/organisms/SectionAlternate/SectionAlternate';
import { GetStarted, Features, Services, Hero } from './components';

const useStyles = makeStyles(() => ({
  sectionAlternateNoPaddingTop: {
    '& .section-alternate__content': {
      paddingBottom: 0,
    },
  },
  dividerSection: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const IndexView = ({ themeMode }) => {
  const classes = useStyles();

  return (
    <div>
      <Hero themeMode={themeMode} />
      <SectionAlternate className={classes.sectionAlternateNoPaddingTop}>
        <Features />
      </SectionAlternate>{' '}
      <Services />
      <Section className={classes.dividerSection}>
        <Divider />
      </Section>
      <Section narrow>
        <GetStarted />
      </Section>
    </div>
  );
};

export default IndexView;
